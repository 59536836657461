import styled from 'styled-components';

export const ReserveBtn = styled.button`
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  background-color: #b99ccf;
  border-color: #b99ccf;
  border-style: none;
  padding: 13px 26px;
  cursor: pointer;
  display: block;
  margin: auto;
  margin-top: 2rem;

  &:hover {
    background-color: #9d85af;
    border-color: #9d85af;
  }
`;
