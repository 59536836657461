import React from 'react';
import { Title } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import crabAgnolotti from '../../images/crab-agnolotti.jpg';
import ribRoast from '../../images/rib-roast.jpg';
import chickenTempura from '../../images/chicken-tempura.jpg';

const Home = () => {
  return (
    <div>
      <Title>ume</Title>
      {/* <Swiper
        slidesPerView={'auto'}
        pagination={{ dynamicBullets: true }}
        centeredSlides
      >
        <SwiperSlide>
          <img
            alt="crab agnolotti"
            src={crabAgnolotti}
            style={{ width: '100vw' }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img alt="rib roast" src={ribRoast} style={{ width: '100vw' }} />
        </SwiperSlide>
        <SwiperSlide>
          <img
            alt="chicken tempura"
            src={chickenTempura}
            style={{ width: '100vw' }}
          />
        </SwiperSlide>
      </Swiper> */}
    </div>
  );
};

export default Home;
