import React from 'react';
import {
  // Box,
  // LargeButton,
  PageContainer,
  ParagraphText,
  Subtitle,
  Title,
} from '../../styles/Base';
// import { Input } from './styles';

const Reserve = () => {
  return (
    <PageContainer>
      <Title to="/">ume</Title>
      <Subtitle>- reservations -</Subtitle>
      <ParagraphText marginTop="2rem">
        The best dining experiences are ones where we share our food and stories
        with others. Each seating of our tasting menu experience is set up in a
        communal dining format, centered around seasonal SoCal fare.
      </ParagraphText>
      <ParagraphText>
        Tickets are released at 10am two weeks prior to the night of dining.
      </ParagraphText>
      <ParagraphText>
        If you should need to cancel your reservation, please notify us at least
        48 hours in advance. Thank you for your understanding.
      </ParagraphText>
      <ParagraphText marginBottom="2rem">
        <b>
          <i>The summer seating will be in August 2022.</i>
        </b>
      </ParagraphText>
      {/* <Box>
        <ParagraphText>
          Subscribe to be notified of the next ticket release.
        </ParagraphText>
        <Input type="tel" placeholder="Enter your phone number" />
        <LargeButton>Subscribe</LargeButton>
      </Box> */}
    </PageContainer>
  );
};

export default Reserve;
