import React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Map from '../Map';
import Marker from '../Map/Marker';
import { PageContainer, Subtitle, Title } from '../../styles/Base';
import { AddressSubtitle, InfoSubtitle, CenteredContainer } from './styles';

const UME_LAT_LNG = { lat: 32.865400915119544, lng: -117.21323616071035 };

const render = (status: Status) => {
  return <h2>{status}</h2>;
};

const Contact = () => {
  return (
    <PageContainer>
      <Title to="/">ume</Title>
      <Subtitle>- connect -</Subtitle>
      <CenteredContainer>
        <AddressSubtitle>8148 GENESEE AVE, SAN DIEGO</AddressSubtitle>
        <div
          style={{
            margin: '3rem auto',
            width: '100%',
            height: '400px',
          }}
        >
          <Wrapper
            apiKey={'AIzaSyCTiCGb4tqWjyBja0HjXG2zMRMnsgJHWnc'}
            render={render}
          >
            <Map
              center={UME_LAT_LNG}
              zoom={13}
              disableDefaultUI={true}
              style={{ flexGrow: '1', height: '100%' }}
            >
              <Marker position={UME_LAT_LNG} />
            </Map>
          </Wrapper>
        </div>
        <InfoSubtitle>e: ume@helenaq.in</InfoSubtitle>
        <InfoSubtitle>p: 408.666.8121</InfoSubtitle>
      </CenteredContainer>
    </PageContainer>
  );
};

export default Contact;
