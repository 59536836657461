import styled from 'styled-components';
import img from '../images/crab-agnolotti.jpg';

export const BgPlumImage = styled.div`
  background-image: linear-gradient(
      rgb(246 239 250 / 70%),
      rgb(252 246 246 / 60%)
    ),
    url(${img});
  background-color: lightgrey;
  background-repeat: unset;
  background-position: 20%;
  background-size: cover;
  min-height: 100vh;
`;
