import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from './constants';

interface Props {
  readonly marginTop?: string;
  readonly marginBottom?: string;
}

export const PageContainer = styled.div`
  padding: 10%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    padding: 5% 10%;
  }
`;

export const Title = styled(Link)`
  margin-bottom: 2rem;
  font-weight: normal;
  font-size: 2.5rem;
  text-decoration: none;
`;

export const Subtitle = styled.h2<Props>`
  font-weight: normal;
  text-align: center;
  margin-bottom: ${(props) => props.marginBottom};
`;

export const H3 = styled.h3`
  font-weight: normal;
`;

export const ParagraphText = styled.p<Props>`
  margin-bottom: ${(props) => props.marginBottom || '1rem'};
  margin-top: ${(props) => props.marginTop || 0};
`;

export const BoldText = styled.p`
  font-weight: 600;
`;

export const Button = styled.button`
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  background-color: #b99ccf;
  border-color: #b99ccf;
  border-style: none;
  padding: 10px 26px;
  cursor: pointer;
  display: block;
  margin-top: 0.5rem;

  &:hover {
    background-color: #9d85af;
    border-color: #9d85af;
  }
`;

export const LargeButton = styled(Button)`
  width: 100%;
`;

export const Box = styled.div<Props>`
  padding: 2rem;
  background: #f9f7f7;
  border-radius: 0.25rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px;
  margin-top: ${(props) => props.marginTop || 0};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;
