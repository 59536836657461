import styled from 'styled-components';

export const AddressSubtitle = styled.h3`
  font-size: 11px;
  margin-top: 3rem;
`;

export const InfoSubtitle = styled.h3`
  font-size: 11px;
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
