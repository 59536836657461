import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  PageContainer,
  ParagraphText,
  Subtitle,
  Title,
} from '../../styles/Base';
import { ReserveBtn } from './styles';

const About = () => {
  const history = useHistory();

  return (
    <PageContainer>
      <Title to="/">ume</Title>
      <div>
        <Subtitle>
          <i>うめ · 梅</i>
        </Subtitle>
        <Subtitle>/u · me/</Subtitle>
        <Box marginTop="2rem">
          <ParagraphText>
            ume is an intimate quarterly micro pop-up that features seasonal
            Californian cuisine. Born out of the pandemic, ume, which means
            “plum” in Japanese, represents hope and beauty thriving in
            adversity.
          </ParagraphText>
          <ParagraphText>
            As the plum tree blossoms between winter and spring, it is a symbol
            of transition - that even in the darkest of times happiness can
            bloom.
          </ParagraphText>
          <ParagraphText>
            We hope to bring warmth and joy when you visit us.
          </ParagraphText>
          <ReserveBtn onClick={() => history.push('/reserve')}>
            Reserve
          </ReserveBtn>
        </Box>
      </div>
    </PageContainer>
  );
};

export default About;
