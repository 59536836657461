import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import GlobalStyle from './styles/global';
import About from './components/About';
import Connect from './components/Connect';
import Home from './components/Home';
import Reserve from './components/Reserve';
import Reservation from './components/Reservation';
import HamburgerBtn from './components/Menu/HamburgerBtn';
import { Li, Nav, Ul, IgLink, StyledIgIcon } from './components/Menu/styles';
import useMenuToggle from './hooks/useToggle';
import { BgPlumImage } from './styles/BgImage';
import 'index.css';

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useMenuToggle();

  return (
    <BgPlumImage>
      <GlobalStyle />
      <Router>
        <div>
          <Nav isMenuOpen={isMenuOpen}>
            <HamburgerBtn
              handleClick={() => setIsMenuOpen()}
              isMenuOpen={isMenuOpen}
            />
            <Ul isMenuOpen={isMenuOpen}>
              <Li onClick={() => setIsMenuOpen()}>
                <Link to="/">ume</Link>
              </Li>
              <Li onClick={() => setIsMenuOpen()}>
                <Link to="/about">about</Link>
              </Li>
              <Li onClick={() => setIsMenuOpen()}>
                <Link to="/reserve">reserve</Link>
              </Li>
              <Li onClick={() => setIsMenuOpen()}>
                <Link to="/connect">connect</Link>
              </Li>
            </Ul>
            <IgLink href="instagram://user?username=umesandiego">
              <StyledIgIcon isMenuOpen={isMenuOpen} />
            </IgLink>
          </Nav>
        </div>
        <div style={{ display: isMenuOpen ? 'none' : 'initial' }}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/reserve">
              <Reserve />
            </Route>
            <Route exact path="/connect">
              <Connect />
            </Route>
            <Route exact path="/reservation">
              <Reservation />
            </Route>
          </Switch>
        </div>
      </Router>
    </BgPlumImage>
  );
};

export default App;
