import React from 'react';
import {
  BoldText,
  Box,
  PageContainer,
  ParagraphText,
  Subtitle,
  Title,
} from '../../styles/Base';

const Reserve = () => {
  return (
    <PageContainer>
      <Title to="/">ume</Title>
      <Subtitle marginBottom="2rem">- reservations -</Subtitle>
      <Box>
        <ParagraphText marginTop="2rem">
          Hello friend, we are beyond excited to have you!
        </ParagraphText>
        <ParagraphText>
          Each seating of our five course tasting menu experience is set up in a
          communal dining format, centered around seasonal SoCal fare.
        </ParagraphText>
        <ParagraphText>
          Seating will be at 6pm, and the cost per person is $25. Payment
          options include Venmo (@Helena-Qin), or whatever is most convenient
          for you.
        </ParagraphText>
        <ParagraphText>
          We have a selection of rotating craft beers, but feel free to BYOB.
        </ParagraphText>
        <ParagraphText>See you soon!</ParagraphText>
        <ParagraphText marginTop="2rem">
          <BoldText>Time:</BoldText>
          <br />
          Aug 14 at 6pm
        </ParagraphText>
        <ParagraphText marginTop="2rem">
          <BoldText>Location:</BoldText>
          <br />
          8148 Genesee Ave, #65, San Diego, CA 92122
        </ParagraphText>
        <ParagraphText marginTop="2rem">
          <BoldText>Parking:</BoldText>
          <br />
          There is no guest parking in the complex, so please find street
          parking
        </ParagraphText>
        <ParagraphText marginTop="2rem">
          <BoldText>Dress Code:</BoldText>
          <br />
          Come as you are :)
        </ParagraphText>
        <ParagraphText marginTop="2rem">
          <i>
            If you should need to cancel your reservation, please notify us at
            least 48 hours in advance. Thank you for your understanding.
          </i>
        </ParagraphText>
      </Box>
    </PageContainer>
  );
};

export default Reserve;
