import styled from 'styled-components';
import { ReactComponent as IgIcon } from '../../images/ig_icon.svg';
import { breakpoints } from '../../styles/constants';

interface MenuProps {
  readonly isMenuOpen?: boolean;
}

export const Nav = styled.nav<MenuProps>`
  height: ${(props) => (props.isMenuOpen ? '100vh' : '0')};
  display: flex;
  flex-direction: column;
  transition: all 0.75s ease-in-out;
  background-color: lavenderblush;
`;

export const Ul = styled.ul<MenuProps>`
  align-self: center;
  display: ${(props) => (props.isMenuOpen ? 'initial' : 'none')};
  margin-top: 20%;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 10%;
  }
`;

export const Li = styled.li`
  list-style: none;
  font-size: 1.5rem;
  text-align: center;

  a {
    text-decoration: none;
    display: block;
    margin-bottom: 1.5rem;
    color: rebeccapurple;
  }
`;

export const IgLink = styled.a`
  align-self: center;
  width: 35px;
  height: 35px;
`;

export const StyledIgIcon = styled(IgIcon)<MenuProps>`
  fill: grey;
  position: absolute;
  bottom: 8%;
  display: ${(props) => (props.isMenuOpen ? 'inherit' : 'none')};
`;
